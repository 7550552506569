const Navbar = () => {
  return (
    <>
      <div className='navbar-div'>
        <div className='navbar'>
          <a className='inner-nav' href='#top'>
            <div id='nav-top'>Top</div>
          </a>
          <a className='inner-nav' href='#about'>
            <div id='nav-about'>About</div>
          </a>
          <a className='inner-nav' href='#skill'>
            <div id='nav-skill'>Skills</div>
          </a>
          <a className='inner-nav' href='#work'>
            <div id='nav-work'>Works</div>
          </a>
          <a className='inner-nav' href='#extra'>
            <div id='nav-extra'>Extra</div>
          </a>
          <a className='inner-nav' href='#bottom'>
            <div id='nav-bottom'>Bottom</div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Navbar;
