import { useState, useEffect } from 'react';

import '../styles/chat.css';

import Message from './Message';
import Notification from './Notification';

let ws;
let sender;

const Chat = () => {
  const date = new Date();

  const [message, setMessage] = useState('');
  const [totalUser, setTotalUser] = useState(0);
  const [messages, setMessages] = useState([]);

  // Render only once
  useEffect(() => {
    document.title = 'CinChat';

    ws = new WebSocket(`wss://${window.location.hostname}`);
    sender = generateSender();

    // Welcome message
    setMessages((messages) => [
      ...messages,
      <Notification notification={`Welcome, ${sender} (You)`} />,
    ]);

    ws.onopen = (e) => {
      // Notify new user entered the chat
      ws.send(
        JSON.stringify({
          type: 'join',
          message: `${sender} joined the chat`,
        })
      );
    };

    window.onbeforeunload = (e) => {
      ws.send(
        JSON.stringify({
          type: 'left',
          message: `${sender} left the chat`,
        })
      );
      ws.close();
    };

    // Get data from server
    ws.onmessage = (e) => {
      let data = JSON.parse(e.data);

      switch (data.type) {
        case 'join':
          setMessages((messages) => [
            ...messages,
            <Notification notification={data.message} />,
          ]);
          break;

        case 'left':
          const notification = {
            bgColor: '#f8cfcf',
            notification: data.message,
          };
          setMessages((messages) => [
            ...messages,
            <Notification {...notification} />,
          ]);
          break;

        case 'totalUser':
          setTotalUser(+data.totalUser);
          break;

        default:
          setMessages((messages) => [
            ...messages,
            <Message {...JSON.parse(e.data)} />,
          ]);
          break;
      }
    };

    return () => {
      // Notify user left the chat
      ws.send(
        JSON.stringify({
          type: 'left',
          message: `${sender} left the chat`,
        })
      );
      ws.close();
    };
  }, []); // useEffect()

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!message) return;

    document.querySelector('#message-bar').focus();

    let data = {
      sender,
      message,
      time: date.toLocaleString('en-MY', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      }),
    };

    let myData = {
      justify: 'flex-end',
      ...data,
    };

    // Your own message
    setMessages((messages) => [...messages, <Message {...myData} />]);
    setMessage('');

    // Send data to server
    ws.send(JSON.stringify(data));
  };

  // Render every changes
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  });

  return (
    <>
      <div className='top-div'>{`Current User: ${totalUser}`}</div>
      <div className='center-div'>{messages}</div>
      <form>
        <div className='bottom-div'>
          <div className='message-div'>
            <input
              type='text'
              id='message-bar'
              placeholder='Type message here'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              autoComplete='off'
            />
            <button id='send-button' onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

function generateSender() {
  const animals = [
    'Ayam',
    'Itik',
    'Kambing',
    'Serigala',
    'Biawak',
    'Harimau',
    'Arnab',
    'Keldai',
    'Kuda',
    'Bangau',
    'Murai',
    'Unta',
    'Cerpelai',
    'Cipan',
    'Lipan',
    'Dubuk',
    'Enggang',
  ];
  const behaviours = [
    'Nakal',
    'Sedih',
    'Gembira',
    'Jahat',
    'Marah',
    'Berani',
    'Bosan',
    'Pemalu',
    'Keliru',
    'Ceria',
    'Pandai',
    'Lucu',
    'Santai',
    'Kuat',
    'Panik',
  ];

  return `${animals[Math.floor(Math.random() * animals.length)]} ${
    behaviours[Math.floor(Math.random() * behaviours.length)]
  }`;
}

export default Chat;
