const About = () => {
  return (
    <>
      <section className='section' id='about'>
        <h3 className='section-title'>
          <b>About</b>
        </h3>
        <div className='grid-section about-section'>
          <div>
            <img src='./images/coding-image.jpg' alt='coding' />
          </div>
          <div>
            <h3>
              <b>Background in Programming</b>
            </h3>
            <p>
              I am a{' '}<x-highlight>Software Engineer</x-highlight>
            </p>
            <p>
              My hobbies are developing <x-highlight>mobile apps</x-highlight>,{' '}
              <x-highlight>games</x-highlight>,{' '}
              <x-highlight>websites</x-highlight> and{' '}
              <x-highlight>learn how computer works.</x-highlight> I started to
              learn code in 2017 by watching YouTube videos and do some online
              courses.
            </p>
            <p>
              My focus is on <x-highlight>back-end</x-highlight> development and
              I like to play with protocols (
              <x-highlight>http, webSocket, webRTC</x-highlight>)
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
