import { Link } from 'react-router-dom';
const Extra = () => {
  return (
    <>
      <section className='section' id='extra'>
        <h3 className='section-title'>
          <b>Extra</b>
        </h3>

        <section className='grid-section work-section'>
          {/* CinChat */}
          <div className='grid-work'>
            <div>
              <Link to='/chat'>
                <img src='./images/15.png' alt='CinChat' />
              </Link>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>CinChat</span>
              <span className='work-tech'>NodeJS | ReactJS | Sass</span>
              <span className='work-desc'>
                Just a brief example of web socket app. I made this while
                developing this portfolio. I use Sass for the styling but it
                doesn't really matter since it will translate the code to CSS.
              </span>
            </div>
          </div>

          {/* Resume */}
          <div className='grid-work'>
            <div>
              <a href='/resume-adib.pdf' target='_blank' rel='noreferrer'>
                <img src='./images/resume-logo.png' alt='resume' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>My Resume</span>
              <span className='work-tech'>Latest</span>
              <span className='work-desc'>
                A little bit info about me and my education background.
              </span>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Extra;
