import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import Home from './components/Home';
import Chat from './components/Chat';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/chat' component={Chat} />
        <Route path='*' component={Home} />
      </Switch>
    </Router>
  );
};

export default App;
