import '../styles/notification.css';

const Notification = ({ bgColor, notification }) => {
  return (
    <>
      <div className='my-notification'>
        <div className='notification-div' style={{ backgroundColor: bgColor }}>
          <div className='notification'>{notification}</div>
        </div>
      </div>
    </>
  );
};

export default Notification;
