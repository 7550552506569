
import '../styles/message.css';

const Message = ({ justify, sender, message, time }) => {
  return (
    <>
      <div className='my-message-div' style={{ justifyContent: justify }}>
        <div className='my-message'>
          <div className='sender'>{sender}</div>
          <div className='message'>{message}</div>
          <div className='time'>{time}</div>
        </div>
      </div>
    </>
  );
};

export default Message;
