const Works = () => {
  return (
    <>
      <section className='section' id='work'>
        <h3 className='section-title'>
          <b>Works</b>
        </h3>

        <section className='grid-section work-section'>
          {/* SPM Sejarah */}
          <div className='grid-work'>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=adibzaini.com.spmsejarah'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/1.png' alt='spm sejarah' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>SPM Sejarah</span>
              <span className='work-tech'>Android Studio | Java</span>
              <span className='work-desc'>
                This is my first project since I learned programming. It's a
                multiple choice question app to help students study even without
                Internet connection. It took me 3 months to finish this project.
                More than 20k devices have installed this app.
              </span>
            </div>
          </div>

          {/* SPM Sains */}
          <div className='grid-work'>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=com.adibzaini.spmsains'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/2.png' alt='spm sains' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>SPM Sains</span>
              <span className='work-tech'>Flutter | Dart</span>
              <span className='work-desc'>
                It's a multiple choice question app to help students study even
                without Internet connection.
              </span>
            </div>
          </div>

          {/* pendek.me */}
          <div className='grid-work'>
            <div>
              <a href='https://pendek.me' target='_blank' rel='noreferrer'>
                <img src='./images/3.png' alt='pendek.me' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>pendek.me</span>
              <span className='work-tech'>
                MongoDB | ExpressJS | ReactJS | NodeJS
              </span>
              <span className='work-desc'>
                My first web app. Your typical URL shortener but I'm proud of
                it. It used to be a Flask app. It migrated from Python to
                JavaScript. This web app is hosted on Cloud Run.
              </span>
            </div>
          </div>

          {/* Arkib */}
          <div className='grid-work'>
            <div>
              <a
                href='https://www.npmjs.com/package/arkib'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/4.png' alt='arkib' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Arkib</span>
              <span className='work-tech'>JavaScript | NodeJS</span>
              <span className='work-desc'>
                My first node module that published on npm public registry. It
                client API that help developers fetch data from National Archive
                of Malaysia. This is an open-source project.
              </span>
            </div>
          </div>

          {/* Sejarah Bot */}
          <div className='grid-work'>
            <div>
              <a
                href='https://twitter.com/sejarahbot'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/5.png' alt='sejarah bot' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Sejarah Bot</span>
              <span className='work-tech'>
                NodeJS | Twitter API | Arkib | Heroku
              </span>
              <span className='work-desc'>
                This bot will tweet about 'Hari ini dalam sejarah' every day &
                tweet a short video by Arkib Negara once a week. I use my own
                node module, Arkib to fetch data from Arkib Negara. This bot is
                hosted on Heroku.
              </span>
            </div>
          </div>

          {/* Typing Bot */}
          <div className='grid-work'>
            <div>
              <a
                href='https://www.youtube.com/watch?v=PLPqXyOLkAI'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/6.png' alt='typing bot' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Typing Bot</span>
              <span className='work-tech'>Python | Selenium</span>
              <span className='work-desc'>
                This project is just for fun. I don't make my source code public
                because it will be violating typeracer.com's rule. I can only
                show you Youtube video about the process & result.
              </span>
            </div>
          </div>

          {/* Find The End */}
          <div className='grid-work'>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=adibzaini.com.findtheend'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/7.png' alt='find the end' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Find The End</span>
              <span className='work-tech'>Unity | C#</span>
              <span className='work-desc'>
                My first game & I have to admit it's a bad game.
              </span>
            </div>
          </div>

          {/* Math Game */}
          <div className='grid-work'>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=com.adibzaini.mathgame'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/8.png' alt='math game' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Math Game</span>
              <span className='work-tech'>Android Studio | Java</span>
              <span className='work-desc'>
                Your typical mathematics game. Try out if you want to strengthen
                your math foundation.
              </span>
            </div>
          </div>

          {/* Learn Malay */}
          <div className='grid-work'>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=com.adibzaini.learnmalay'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/9.png' alt='learn malay' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Learn Malay</span>
              <span className='work-tech'>Android Studio | Java</span>
              <span className='work-desc'>
                You can learn how to write & pronounce Malay words & sentences
                here. I use my own voice.
              </span>
            </div>
          </div>

          {/* It's Going Down */}
          <div className='grid-work'>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=com.adibzaini.itsgoingdown'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/10.png' alt='its going down' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>It's Going Down</span>
              <span className='work-tech'>Unity | C#</span>
              <span className='work-desc'>
                I like this game. It's not challenging at all. It's just pure
                evil.
              </span>
            </div>
          </div>

          {/* Lantun */}
          <div className='grid-work'>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=com.adibzaini.Lantun'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/11.png' alt='lantun' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Lantun</span>
              <span className='work-tech'>Unity | C# | Blender</span>
              <span className='work-desc'>
                This one is simple. You need to finish all levels. I like the
                character that I designed by using Blender.
              </span>
            </div>
          </div>

          {/* Pecut */}
          <div className='grid-work'>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=com.adibzaini.pecut'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/12.png' alt='pecut' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Pecut</span>
              <span className='work-tech'>Unity | C# | Blender</span>
              <span className='work-desc'>
                Help Pecut collect duit raya! For me, I love the environment of
                this game and the music is soothing. It has Android & Browser
                version.{' '}
                <a
                  href='https://play.google.com/store/apps/details?id=com.adibzaini.pecut'
                  target='_blank'
                  rel='noreferrer'
                >
                  Android Version
                </a>
                {' | '}
                <a
                  href='https://adibzter.itch.io/pecut'
                  target='_blank'
                  rel='noreferrer'
                >
                  Browser Version
                </a>
              </span>
            </div>
          </div>

          {/* UiTM iStudent Brute */}
          <div className='grid-work'>
            <div>
              <a
                href='https://www.youtube.com/watch?v=4fsNjRiZnDo'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/13.png' alt='uitm istudent brute' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>UiTM iStudent Brute</span>
              <span className='work-tech'>Bash | Kali Linux</span>
              <span className='work-desc'>
                Since it's illegal to hack someone else. I try to brute-force my
                own UiTM student's account. I don't make the script public since
                it can harm UiTM. I only can provide you Youtube video.
              </span>
            </div>
          </div>

          {/* Youtube API */}
          <div className='grid-work'>
            <div>
              <a
                href='https://www.youtube.com/watch?v=aOARYxFljbc'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/14.png' alt='youtube api' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Youtube API</span>
              <span className='work-tech'>Python</span>
              <span className='work-desc'>
                I use Python and Youtube API to change my video's title every 8
                minutes. The programme sometimes stopped because exceeded daily
                CPU usage at pythoneverywhere.com.
              </span>
            </div>
          </div>

          {/* Sementara */}
          <div className='grid-work'>
            <div>
              <a
                href='https://sementara.skrin.xyz'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/16.png' alt='sementara' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Sementara</span>
              <span className='work-tech'>
                NodeJS | ReactJS | Google Storage
              </span>
              <span className='work-desc'>
                An open-source web application which allow user to upload and
                share temporary file using QR code.
              </span>
            </div>
          </div>

          {/* Kamus */}
          <div className='grid-work'>
            <div>
              <a
                href='https://chrome.google.com/webstore/detail/kamus/fckpmjbalgabmcollcegjgephomjfpfa'
                target='_blank'
                rel='noreferrer'
              >
                <img src='./images/17.png' alt='kamus' />
              </a>
            </div>
            <div className='grid-work-detail'>
              <span className='work-title'>Kamus</span>
              <span className='work-tech'>Chrome Extension</span>
              <span className='work-desc'>
                An open-source chrome extension for getting malay & english
                words definition from Dewan Bahasa dan Pustaka's Pusat Rujukan
                Persuratan Melayu.
              </span>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Works;
