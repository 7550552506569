import Top from './Top';
import About from './About';
import Skills from './Skills';
import Works from './Works';
import Extra from './Extra';
import Footer from './Footer';
import Navbar from './Navbar';

const Home = () => {
  return (
    <>
      <Top />
      <br />
      <About />
      <br />
      <Skills />
      <br />
      <Works />
      <br />
      <Extra />
      <br />
      <Footer />
      <Navbar />
    </>
  );
};

export default Home;
